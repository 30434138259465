.image-container {
    position: relative;
    border-radius: 25px;
    overflow: hidden;
}
  
.image-overlay {
    content: "";
    position: absolute;
    border-radius: 25px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
    pointer-events: none; /* Allow interactions with the image */
}