.css-1cdu57t {
    text-align: center;
    padding: 2px;
    color: #fff;
    width: 70%;
    margin: auto;
}

.css-1okhqwi{
    display: flex !important;
}
.mobile-version{
    display: none !important;
}
.right-btn{
    background-color: #b99ff7;
    height: 30px;
    width: 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    color: #fff;
    margin: 0 5px;
    padding: 5px;
}

.left-btn{
    background-color: #b99ff7;
    height: 30px;
    width: 30px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    color: #fff;
    margin: 0 5px;
    padding: 5px;
}
@media only screen and (max-width: 768px) {
    .desktop-version{
        display: none !important;
    }
    .mobile-version{
        display: block !important;
    }


}

@media only screen and (max-width: 468px){

}