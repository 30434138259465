.mobile-version{
    display: none !important;
}


@media only screen and (max-width: 768px) {
    .desktop-version{
        display: none !important;
    }
    .mobile-version{
        display: block !important;
    }

}


@media only screen and (max-width: 468px){
    .live-cast {
         width: 95% !important;
     }
 }