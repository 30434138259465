.mobile-banner{
    display: none;
}

@media only screen and (max-width: 768px) {
    .desktop-banner{
        display: none;
    }
    .mobile-banner{
        display: block;
    }
    .mobile-banner .css-670mwf {
        font-size: 36px;
    }
    .bg_banner{
        background-position:80%;
        height: 256px !important;
    }
}