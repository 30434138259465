@font-face {
  font-family: ProximaNova;
  src: url('./assets/fonts/ProximaNova.otf');
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.slick-next, .slick-prev{
  border:2px solid rgba(149, 151, 171, 1) !important;
  color: rgba(149, 151, 171, 1) !important;
  border-radius: 15px;
  padding: 5px;
}
p{
  margin: 0;
  padding: 0;
}

*{
  margin: 0;
  padding: 0;
}

/* responsive */


@media only screen and (max-width: 1500px) {
  .css-zg2ffs {
    width: 85% !important;
  }
  .css-1pz2eex{
    width: 85% !important;
  }
  .css-1fg07fx {
    height: 60px !important;
    width: 60px !important;
  }
  .css-ms5633{
    height: 100% !important;
  }
  .chakra-stack.css-ed2j0k {
    width: 85% !important;
    margin: auto;
  }
}