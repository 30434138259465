.mobile-version{
    display: none !important;
}

@media only screen and (max-width: 768px) {
    .desktop-version{
        display: none !important;
    }
    .mobile-version{
        display: block !important;
    }
    .css-1pz2eex{
        width: 100%;
    }

}

@media only screen and (max-width: 468px){

}