.channel-box:hover{
    background-color:rgba(96, 31, 235, 1);
}

.channel-box:hover p{
    color: #fff !important;
}

.mobile-channel{
    display: none;
}

@media only screen and (max-width: 768px) {
    .desktop-channel{
        display: none;
    }
    .mobile-channel{
        display: block;
    }

}