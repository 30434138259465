.image-container {
    position: relative;
    border-radius: 25px;
    overflow: hidden;
    bottom: 0;
}
  
.image-overlay {
    content: "";
    position: absolute;
    border-radius: 25px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6));
    pointer-events: none; /* Allow interactions with the image */
}

.mobile-feature{
    display: none ;
}

@media only screen and (max-width: 768px) {
    .desktop-feature{
        display: none ;
    }
    .mobile-feature{
        display: block;
    }
}